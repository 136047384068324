import { BigNumber, ethers } from "ethers";
const genericErc20Abi = require("./erc20.json");

let provider = null;

if (typeof window.ethereum != "undefined") {
    provider = new ethers.providers.Web3Provider(window.ethereum);

    ethereum.on("chainChanged", (chainId) => {
        provider = new ethers.providers.Web3Provider(window.ethereum);
        console.log("Web3 Provider updated", provider);
    });
}

const connectWallet = async () => {
    if (!window.ethereum) {
        alert("MetaMask not detected. Please install MetaMask first.");
        return;
    }

    const { chainId } = await provider.getNetwork();
    console.log(chainId);

    await provider.send("eth_requestAccounts", []);
};

const makePayment = async (value, tokenAddress, chainId, toAddress) => {
    await connectWallet();
    setChain(chainId).then((res) => {
        console.log("Wait 2 secs", res);
        setTimeout(
            async (res) => {
                console.log("Res setChain", res);
                if (res) {
                    await transfer(value, tokenAddress, toAddress);
                }
            },
            2000,
            res
        );
    });
};

const transferNative = async (toAddress, amount) => {
    if (amount == 0 || amount < 0) {
        alert("Invalid amount");
        return;
    }

    if (!window.ethereum) {
        alert("MetaMask not detected. Please install MetaMask first.");
        return;
    }

    const { chainId } = chainId ? chainId : await provider.getNetwork();
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    const senderAddress = await signer.getAddress();
    let balanceMaticHex = await provider.send("eth_getBalance", [
        senderAddress,
        "latest",
    ]);
    let balanceMaticBigInt = BigNumber.from(balanceMaticHex).toBigInt();
    let balanceMatic = ethers.utils.formatEther(balanceMaticBigInt);

    if (parseFloat(balanceMatic) < parseFloat(amount)) {
        showAlert("Insufficient funds in the wallet", "warning");
        return;
    }

    provider.getGasPrice().then((currentGasPrice) => {
        let currGasPrice = ethers.utils.hexlify(parseInt(currentGasPrice));
        console.log("GAS price: ", currGasPrice);
        let gasValue = "0x76c0";
        let needAmount =
            gasValue * currGasPrice + ethers.utils.parseEther(amount)._hex;
        console.log("Need amount: ", needAmount);
        let params = [
            {
                from: senderAddress,
                to: toAddress,
                gas: gasValue, // 30400
                gasPrice: currGasPrice,
                value: ethers.utils.parseEther(amount)._hex,
            },
        ];

        console.log(params);

        provider
            .send("eth_sendTransaction", params)
            .then((trxHash) => {
                console.log(trxHash);
            })
            .catch((error) => {
                console.error(error);
            });
    });
};

const transferToken = async (value, tokenAddress, toAddress) => {
    let token = {
        address: tokenAddress,
    };

    await registerToken(token);

    let balance = await tokenBalance(tokenAddress);
    console.log(
        "Transfer token",
        value,
        parseInt(ethers.utils.parseEther(value)._hex, balance)
    );

    if (parseInt(ethers.utils.parseEther(value)._hex) > balance) {
        window.showAlert("Not enough token", "error", 2000);
        return;
    }
    const TRANSFER_FUNCTION_ABI = [
        {
            constant: false,
            inputs: [
                { name: "_to", type: "address" },
                { name: "_value", type: "uint256" },
            ],
            name: "transfer",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
    ];
    let iface = new ethers.utils.Interface(TRANSFER_FUNCTION_ABI);
    const transactionParameters = {
        from: ethereum.selectedAddress,
        to: tokenAddress,
        data: iface.encodeFunctionData("transfer", [
            toAddress,
            ethers.utils.parseEther(value)._hex,
        ]),
        chainID: parseInt(ethereum.chainId),
    };
    await ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
    });
};

const transfer = async (value, tokenAddress, toAddress) => {
    console.log("Transfer", value, tokenAddress, toAddress);
    if (tokenAddress.length > 0 && tokenAddress != "0x0") {
        transferToken(value, tokenAddress, toAddress);
    } else {
        transferNative(toAddress, value);
    }
};

const getTransaction = async (transactionHash) => {
    provider
        .getTransactionReceipt(transactionHash)
        .then(function (transactionReceipt) {
            console.log(transactionReceipt);
            return transactionReceipt;
        });
};

const registerToken = async (token) => {
    const tokenAddress = token.address;
    const tokenSymbol = token.symbol;
    const tokenDecimals = token.decimals;
    const tokenImage = token.icon;

    try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await ethereum.request({
            method: "wallet_watchAsset",
            params: {
                type: "ERC20", // Initially only supports ERC20, but eventually more!
                options: {
                    address: tokenAddress, // The address that the token is at.
                    symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                    decimals: tokenDecimals, // The number of decimals in the token
                    image: tokenImage, // A string url of the token logo
                },
            },
        });

        if (wasAdded) {
            console.log("Thanks for your interest!");
        } else {
            console.log("Your loss!");
        }
    } catch (error) {
        console.error(error);
    }
};

const setChain = async (chain) => {
    if (typeof provider !== "undefined") {
        let { chainId } = await provider.getNetwork();
        console.log("Current chainId: ", chainId);
        console.log("New chainId: ", chain);
        if (chainId != chain) {
            var params = [window.CHAINS[chain]];
            console.log("Chain params", params);
            return window.ethereum
                .request({ method: "wallet_addEthereumChain", params })
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    console.error(error);
                    return false;
                });
        }
        console.log("Chain not change");
        return true;
    } else {
        alert("Unable to locate a compatible web3 browser!");
        return false;
    }
};

const tokenBalance = async (tokenContractAddress) => {
    const contract = new ethers.Contract(
        tokenContractAddress,
        genericErc20Abi,
        provider
    );
    const balance = (
        await contract.balanceOf(ethereum.selectedAddress)
    ).toString();
    console.log("Token balance", balance);
    return balance;
};

const web3Login = async () => {
    if (!window.ethereum) {
        alert("MetaMask not detected. Please install MetaMask first.");
        return;
    }

    const { chainId } = await provider.getNetwork();

    let strChainId = `0x${parseInt(chainId).toString(16)}`;
    console.log(strChainId);

    let response = await axios
        .get("/web3-login-message")
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            window.showErrorResponse(error);
        });

    const message = response.data.message;

    await provider.send("eth_requestAccounts", []);
    const address = await provider.getSigner().getAddress();
    const signature = await provider.getSigner().signMessage(message);

    response = await axios.post("/web3-login-verify", {
        address: address,
        signature: signature,
        chainId: chainId,
        network: provider.network.name,
        client: window.ethereum.isMetaMask ? "MetaMask" : "",
    });

    const data = response.data;

    if (data.success && data.data.needEmail) {
        $(".btn-close").click();

        let metamaskUser = {
            address: address,
            email: '',
            chainId: strChainId,
            network: provider.network.name,
            signature: signature,
            client: window.ethereum.isMetaMask ? "MetaMask" : "",
        };

        window.metamaskUser = metamaskUser;

        showModal("metamask-email");

        // Swal.fire({
        //     title: 'Введите ваш Email',
        //     input: 'email',
        //     inputAttributes: {
        //         autocapitalize: 'off'
        //     },
        //     showCancelButton: true,
        //     confirmButtonText: 'OK',
        //     showLoaderOnConfirm: true,
        //     preConfirm: async (email) => {
        //         if (validateEmail(email)) {
        //             axios.post('/web3-register', {
        //                 'address': address,
        //                 'email': email,
        //                 'chainId': strChainId,
        //                 'network': provider.network.name,
        //                 'signature': signature,
        //                 'client': window.ethereum.isMetaMask ? 'MetaMask' : '',
        //             }).then((response) => {
        //                 console.log('RESP:', response)
        //                 response.data.success ?
        //                     location.href = response.data.data.route : alert(response.data.data.message);
        //             }).catch(error => {
        //                 window.showErrorResponse(error);
        //             });
        //         }
        //     }
        // });
    }

    if (data.success && !data.data.needEmail) {
        axios
            .post("/web3-auth", {
                address: address,
                chainId: chainId,
                network: provider.network.name,
                signature: signature,
                message: message,
                client: window.ethereum.isMetaMask ? "MetaMask" : "",
            })
            .then((response) => {
                console.log("RESP:", response);
                response.data.success
                    ? (location.href = response.data.data.route)
                    : alert(response.data.data.message);
            })
            .catch((error) => {
                window.showErrorResponse(error);
            });
    }
};

const MyWeb3 = {
    connectWallet: connectWallet,
    provider: provider,
    getTransaction: getTransaction,
    setChain: setChain,
    makePayment: makePayment,
    registerToken: registerToken,
    tokenBalance: tokenBalance,
    web3Login: web3Login,
};

window.MyWeb3 = MyWeb3;
